import React from "react";
import "./About.css";
import me from "./img/Me.png";
const About = () => {
  return (
    <section id="section-about">
      <div className="about">
        <p className="header-about">Skills</p>
        <div className="skill-list">
          <div className="soft-skills">
            <h3>(Soft) Skills</h3>
            <p>Communication skills</p>
            <p>Collaboration</p>
            <p>Time Managment</p>
            <p>Attention to details</p>
            <p>Organizing</p>
          </div>

          <div className="skills">
            <h3>Skills</h3>
            <p>User research</p>
            <p>Prototyping</p>
            <p>Wireframing</p>
            <p>Design Patterns</p>
          </div>

          <div className="tools">
            <h3>Tools</h3>
            <p>Photoshop</p>
            <p>Figma</p>
            <p>Gitlab</p>
            <p>Adobe XD</p>
            <p>Azure</p>
          </div>

          <div className="coding-languages">
            <h3>Coding Languages</h3>
            <p>React js</p>
            <p>Vuejs</p>
            <p>.NET Blazor</p>
            <p>HTML/CSS/JS</p>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </section>
  );
};
export default About;
