import React from "react";
import PropTypes from "prop-types";
import "./project-showcase.css";

function ProjectShowcase(props) {
  return (
    <div className="project">
      <section className="section-intro-project">
        <h2>{props.name}</h2>
        <hr className="long-hr" />

        <img
          className="projects-header-img"
          src={props.img}
          alt="Project header"
        />
        <br />
        <div className="flex-row">
          <div className="flex-col">
            <h3>Project Description</h3>
            <p className="project-description">
              {props.description} <br></br>
            </p>
          </div>
          <div className="flex-col ">
            <div className="extra-info">
              <h3>Tools</h3>
              <p>{props.tools}</p>
            </div>
            <div className="extra-info">
              <h3>Role:</h3>
              <p>{props.role}</p>
            </div>
            {props.link && (
              <div className="extra-info">
                <h3>Link:</h3>
                <p>
                  <a href={props.link}>{props.linkName}</a>
                  <br />
                  {props.link2 && <a href={props.link2}>{props.linkName2}</a>}
                </p>
                <br></br>
                <br></br>
              </div>
            )}
          </div>
        </div>
      </section>
      <br></br> <br></br> <br></br>
      <section id="section">
        <div className="process-section">
          <h1>Process:</h1>
          {props.processSteps.map((step, index) => (
            <div key={index}>
              <br />
              <p>{step.text}</p> <br />
              {step.image && <img src={step.image} alt={`Step ${index}`} />}
              {step.images && (
                <div className="image-container">
                  {step.images.map((image, i) => (
                    <img key={i} src={image} alt={`Step ${index} Image ${i}`} />
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

ProjectShowcase.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tools: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  link: PropTypes.string, // Optional link prop
  linkName: PropTypes.string, // Optional link name prop
  link2: PropTypes.string, // Optional second link prop
  linkName2: PropTypes.string, // Optional second link name prop
  processSteps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node.isRequired,
      image: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

export default ProjectShowcase;
