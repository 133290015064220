import React, { useState } from "react";
import "./HomePage.css";
import me from "./img/Me.png";

const Home = () => {
  const [showimg, setshowimg] = useState(false);
  return (
    <section id="#section-home">
      <div className="home-container">
        <p className="title">
          FRONT END DEVELOPER & <br /> ux/ui DESIGNER
        </p>

        <div className="bottom-section">
          <p className="introduction">
            <span
              className="hover-trigger"
              onMouseLeave={() => setshowimg(false)}
              onMouseEnter={() => setshowimg(true)}
            >
              {" "}
              Hello - I am Navisa Sabeti, frontend developer graduated from
              fontys university of applied sciences based in Maastricht, The
              Netherlands.{" "}
            </span>
          </p>
          <div className="image-container">
            <img
              src={me}
              alt="Me"
              className="hover-image"
              style={{ display: showimg ? "block" : "none" }}
            />
          </div>
          <p className="connect">
            <h3>Lets Connect!</h3> <hr className="hr" />
            <li>
              <a href="https://www.linkedin.com/in/navisa-sabeti-a0ba671ba">
                LinkedIn
              </a>
            </li>
            <li>
              <a href="mailto:navisasabeti10@gmail.com">Email</a>
            </li>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Home;
